.network {
  place-self: center;
  width: 100%;

  min-width: 1050px;

  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-row-gap: 20px;
}

.netLegend {
  place-self: center;
  display: grid;
  width: 75%;

  grid-template-rows: 1fr;
  grid-template-columns: auto auto auto auto auto auto;
}

.netLegendNodeContainer {
  place-self: center start;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
}

.netLegendCircle {
  place-self: center;
  height: 20px;
  width: 20px;
  background-color: #7d3154;
  border-radius: 50%;
  margin-right: 5px;
}

.netLegendTriangle {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 20px solid #008080;
  margin-right: 5px;
}

.netLegendDiamond {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #005b96;
  position: relative;
  top: -10px;
  margin-right: 5px;
}
.netLegendDiamond:after {
  content: "";
  position: absolute;
  left: -10px;
  top: 10px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #005b96;
}

.netLegendEdgeContainer {
  place-self: center end;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
}

.netLegendValidated {
  place-self: center;
  height: 5px;
  width: 50px;
  border-radius: 5px;
  background-color: #ff6f69;
}

.netLegendPredicted {
  place-self: center;
  height: 5px;
  width: 50px;
  border-radius: 5px;
  background-color: #ffcc5c;
}

.netLegendCorrelation {
  place-self: center;
  height: 5px;
  width: 50px;
  border-radius: 5px;
  background-color: #88d8b0;
}

.netViewer {
  width: 75%;
  height: 40rem;
}

.netTable {
  width: 80%;
  height: auto;
  min-height: 15rem;
  min-width: 1050px;
  box-sizing: border-box;
  box-shadow: inset 3px 1px 5px lightgray;

  border: 1px solid lightgray;
  border-radius: 5px;

  display: grid;
  grid-row-gap: 30px;
}

.tableRelative {
  position: relative;
  place-self: center;

  height: 100%;
  width: 100%;

  display: grid;
  overflow: auto;
}

.tableAbsolute {
  width: 100%;

  top: 0;
  position: absolute;
  place-self: center;

  display: grid;
  grid-row-gap: 20px;
}

.table {
  width: 100%;

  place-self: center;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
}

.netTitle,
.netViewer,
.netTable {
  place-self: center;
}

.rt-td {
  white-space: normal !important;
}
