.container {
  width: 100vw;
  min-height: 100vh;

  display: grid;

  grid-template-columns: auto;
  grid-template-rows: 250px auto auto auto;
  grid-row-gap: 10px;
}

/* HOMEPAGE HEADER */

.homepageHeader {
  display: block;
  background: url("compressed-ritaglio-image1-MDS-mod.jpg") no-repeat scroll 50% 50%;
  background-size: cover;

  box-shadow: 0 0 10px 8px white inset;
}

.homepageTitle {
  place-self: center;

  font-size: 5rem;
  color: white;
  font-family: "Verdana", "Helvetica", "Ubuntu", sans-serif;

  background-color: rgba(255, 255, 255, 0.5);

  border-radius: 50px;
  padding: 5px 30px 5px 30px;

  box-shadow: 0 0 20px 2px white inset;
  text-shadow:
          3px 3px 0 #3d3c3c,
          -1px -1px 0 #3d3c3c,
          1px -1px 0 #3d3c3c,
          -1px 1px 0 #3d3c3c,
          1px 1px 0 #3d3c3c;
}

/*  STARTING OPTIONS COMPONENT  */

.startingOptions {
  width: 75%;
  height: 300px;
  place-self: center;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1rem auto 1rem auto 1rem auto;

}

.horizontalLine {
  place-self: center;
}

.bullet {
  margin-right: 10px;
}

.homepageSearchContainer {
  place-self: center;

  width: 70%;
  height: 100%;

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
}

.hpSearchTitle {
  place-self: center start;

  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.homepageNetwork, .homepagePathways, .homepageHelp {
  place-self: center;

  width: 70%;
  height: auto;

  display: flex;
  align-items: center;
}

.hpNetworkTitle, .hpPathwaysTitle, .hpHelpTitle {
  margin-right: 10px;
  font-size: 1.2rem;
}

/* Toggle Choices Container */

.toggleChoicesContainer {
  grid-row: 2;

  place-self: center;

  width: auto;
  height: auto;

  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
}

.toggleArrow {
  place-self: center;
  cursor: pointer;
}

.toggleArrow:hover {
  color: #1245d5;
}

.descriptionChoice {
  place-self: center;
  margin-right: 10px;
  font-size: 1.3rem;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 7px;
  cursor: pointer;

}

.analysisChoice {
  place-self: center;
  margin-left: 10px;
  font-size: 1.3rem;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 7px;
  cursor: pointer;
}


/* Description */

.description {
  place-self: center;

  width: 90%;
  height: auto;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-row-gap: 30px;
}

