.containerAmbiguous {
  border: 1px solid lightgray;
  border-radius: 10px;

  display: grid;

  width: 50%;
  height: auto;

  place-self: center;
}