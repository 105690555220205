.containerPathway {
  width: 100vw;
  min-height: 100vh;
  min-width: 1300px;

  display: grid;

  grid-template-columns: auto;

  /*footer*/
  grid-template-rows: auto 300px;
}

.pathway {
  place-self: center;
  width: 100%;
  height: 100%;

  display: grid;

  grid-template-columns: auto;
  grid-template-rows: 5rem auto 3rem auto auto auto;
  grid-row-gap: 30px;

  min-height: 100%;
}

.pathwayHeader, .pathwayContainerForm, .pathwayInfoElement, .pathwayTableContainer {
  place-self: start center;
}

.pathwayForm {
  display: grid;
}

.pathwayInput {
  place-self: center;
  margin-top: 2px
}

.pathwayInfo {
  place-self: center;
  width: 75%;
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.linkTitle:hover {
  cursor: pointer;
}

.linkTitle {
  color: blue;
  text-decoration: underline;
}

.pathwayNetwork {
  place-self: center;
  width: 100%;

  min-width: 1050px;

  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 20px;
}

.pathwayTitle {
  place-self: center;
}

.patLegend {
  place-self: center;
  display: grid;
  width: 75%;

  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto auto auto auto auto;
}

.patLegendNodeContainer {
  place-self: center start;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
}

.patLegendCircle {
  place-self: center;
  height: 20px;
  width: 20px;
  background-color: #7d3154;
  border-radius: 50%;
  margin-right: 5px;
}

.patLegendTriangle {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 20px solid #008080;
  margin-right: 5px;
}

.patLegendDiamond {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #005b96;
  position: relative;
  top: -10px;
  margin-right: 5px;
}
.patLegendDiamond:after {
  content: "";
  position: absolute;
  left: -10px;
  top: 10px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #005b96;
}

.patLegendSquare {
  place-self: center;
  height: 20px;
  width: 20px;
  background-color: #111111;
  margin-right: 5px;
}

.patLegendEdgeContainer {
  place-self: center end;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
}

.patLegendValidated {
  place-self: center;
  height: 5px;
  width: 40px;
  border-radius: 5px;
  background-color: #ff6f69;
}

.patLegendPredicted {
  place-self: center;
  height: 5px;
  width: 40px;
  border-radius: 5px;
  background-color: #ffcc5c;
}

.patLegendCorrelation {
  place-self: center;
  height: 5px;
  width: 40px;
  border-radius: 5px;
  background-color: #88d8b0;
}

.patLegendOrigin{
  place-self: center;
  height: 5px;
  width: 40px;
  border-radius: 5px;
  background-color: #a7adba;
}


/* PATHWAY GRAPH SECTION */


.pathwayGraphSection {
  margin-bottom: 20px;

  place-self: center;
  width: 100%;

  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto;
  grid-row-gap: 20px;
}

.pathwayGraph {
  width: 75%;
  height: 40rem;
  place-self: center;
  margin-bottom: 10px;
}

@media (max-width: 1300px) {

  .patLegend {
    place-self: center;
    display: grid;
    width: 75%;

    grid-template-rows: auto auto;
    grid-template-columns: auto auto auto auto;
    grid-row-gap: 10px;
  }

  .patLegendNodeContainer {
    place-self: center;
  }

  .patLegendEdgeContainer {
    place-self: center ;
  }

}