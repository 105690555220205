.viewer {
  width: 100%;
  height: 100%;

  --min-width: 80rem;
  min-width: var(--min-width);
}

.container {
  display: grid;

  grid-template-rows: 1rem auto auto auto 4rem auto 4rem auto 300px;
  grid-template-columns: auto;
  grid-row-gap: 2rem;
}

.title,
.expression,
.containerSpinner,
.correlationTitle,
.correlation,
.footer {
  place-self: center;
}

.title {
  font-weight: normal;
}

.expression {
  width: 100%;

  box-sizing: border-box;
  border: 10px solid white;
  display: grid;

  grid-template-rows: auto auto;
}

.chart {
  place-self: center;
}

.legend {
  place-self: center;

  width: 50%;
  max-width: 500px;

  display: grid;
  grid-row-gap: 10px;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto auto auto;
}

.elemLegend {
  place-self: center;
}

.legendLine {
  grid-column: 1/5;

  display: grid;
  grid-template-columns: auto auto auto auto;
}

.rt-td {
  white-space: normal !important;
}


.footer {
  place-self: center;
  height: 100%;
  width: 100%;

  border-top: 1px solid gray;

  display: grid;

  grid-template-rows: 150px 150px;
  grid-template-columns: 1fr 3fr 1fr;
}

.citeUs {
  grid-column: 2;

  place-self: center;
}

.unipd {
  height: auto;

  grid-column: 2;
  grid-row: 2;

  place-self: center;
}
