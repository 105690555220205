
.epSearchContainer {
  position: fixed;
  background-color: white;
  z-index: 100;
  border: 1px solid lightgrey;

  width: 100%;
  height: 3rem;
  min-width: var(--min-width);
}

.epSearch {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 3fr 27fr 1fr 11fr;
}

.epNavbarDropdown {
  place-self: center;
  padding: 3px 7px;

  display: grid;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-sizing: border-box;

  cursor: pointer;
}

.epNavbarDropdown:hover {
  border: 2px solid dodgerblue;
}

.epFai {
  place-self: center;
}

.epNavbarDropdownContent {
  display: none;
  position: absolute;
  background-color: white;
  margin-left: -7px;
  /*min-width: 160px;*/
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid lightgray;
  top: 2.8rem;
}

/* Links inside the dropdown */
.epNavbarDropdownContent a {
  float: none;
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.epNavbarDropdownContent a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.epNavbarDropdown:hover .epNavbarDropdownContent {
  display: block;
}

.epSearchBar {
  width: 60%;
  height: 50%;
  place-self: center end;
}

.epSearchButton {
  place-self: center start;
}

/* Autocomplete Box */

.epContainerOptionsAbsolute {
  text-align: center;
  position: absolute;
  width: 100%;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 3fr 27fr 1fr 11fr;
}

.epContainerOptionsList {
  width: 62%;
  place-self: center end;
}

.epOptionsList {
  width: 100%;
  min-width: 223px;
  height: 100%;

  background-color: #f8f8f8;
  z-index: 2;
  border: 1px solid #999;
  border-radius: 5px;
  list-style: none;
  max-height: 100px;
  overflow-y: auto;

  /*margin: 0;*/
  margin-top: -7px;
  padding-left: 0;

}

.epOptionsList li {
  padding: 0.1rem;
}

.epOptionsList li:hover {
  background-color: #cdd5e8;
  cursor: pointer;
  font-weight: 700;
}

.epOptionsList li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.epNoOptionsList {
  padding: 0.3rem;
  background-color: #f8f8f8;
  border: 1px solid #999;
  color: darkred;
  border-radius: 5px;
  min-width: 223px;
  font-weight: 500;
  margin-top: -7px;
}
