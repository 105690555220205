.containerUserNetwork {
    width: 100vw;
    min-height: 100vh;

    display: grid;

    grid-template-columns: auto;

                           /*footer*/
    grid-template-rows: auto 300px;
}

.userNetwork {
    place-self: center;
    width: 100%;
    height: 100%;

    /*border: 1px solid lightgray;*/

    display: grid;

    grid-template-columns: auto;
    grid-template-rows: 5rem auto auto auto auto;
    grid-row-gap: 30px;

    min-height: 100%;
}

.userTitle, .userFormContainer, .userInfoElement {
    place-self: start center;
}

.userForm {
    display: grid;
}

.userInput {
    place-self: center;
    margin-top: 2px
}

.userInfo {
    place-self: center;
    width: 75%;
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.simpleGraphSection {
    margin-top: 20px;
    margin-bottom: 20px;

    place-self: center;
    width: 100%;

    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto;
    grid-row-gap: 20px;
}

.userGraph {
    width: 75%;
    height: 40rem;
    place-self: center;
    margin-bottom: 10px;
}

.rejectedContainer {
    grid-row: 4;

    place-self: center;

    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    grid-row-gap: 5px;
}

.buttonRejectedNodes {
    place-self: center;
}

/* User Network Legend */


.UNLegend {
    place-self: center;
    display: grid;
    width: 75%;

    grid-template-rows: auto;
    grid-template-columns: auto auto auto auto auto auto auto;
}

.UNLegendNodeContainer {
    place-self: center start;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
}

.UNLegendCircle {
    place-self: center;
    height: 20px;
    width: 20px;
    background-color: #7d3154;
    border-radius: 50%;
    margin-right: 5px;
}

.UNLegendTriangle {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 20px solid #008080;
    margin-right: 5px;
}

.UNLegendDiamond {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #005b96;
    position: relative;
    top: -10px;
    margin-right: 5px;
}
.UNLegendDiamond:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 10px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #005b96;
}

.UNLegendSquare {
    place-self: center;
    height: 20px;
    width: 20px;
    background-color: #111111;
    margin-right: 5px;
}

.UNLegendEdgeContainer {
    place-self: center end;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
}

.UNLegendValidated {
    place-self: center;
    height: 5px;
    width: 40px;
    border-radius: 5px;
    background-color: #ff6f69;
}

.UNLegendPredicted {
    place-self: center;
    height: 5px;
    width: 40px;
    border-radius: 5px;
    background-color: #ffcc5c;
}

.UNLegendCorrelation {
    place-self: center;
    height: 5px;
    width: 40px;
    border-radius: 5px;
    background-color: #88d8b0;
}


@media (max-width: 1200px) {
    .UNLegend {
        place-self: center;
        display: grid;
        width: 75%;

        grid-template-rows: auto auto;
        grid-template-columns: auto auto auto auto;
        grid-row-gap: 10px;
    }

    .UNLegendNodeContainer {
        place-self: center;
    }

    .UNLegendEdgeContainer {
        place-self: center ;
    }
}

