
.absoluteMenu {
  position: fixed;

  margin-top: 1.5rem;
  margin-left: 1.5rem;

  padding: 3px 7px;

  display: grid;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: white;

  cursor: pointer;
}

.absoluteMenu:hover {
  border: 2px solid dodgerblue;
}

.fai {
  place-self: center;
}

.absoluteMenu-content {
  display: none;
  position: absolute;
  background-color: white;
  margin-left: -4px;
  /*min-width: 160px;*/
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid lightgray;
  top: 2.5rem;
}

/* Links inside the dropdown */
.absoluteMenu-content a {
  float: none;
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.absoluteMenu-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.absoluteMenu:hover .absoluteMenu-content {
  display: block;
}
