.hpSearchContainer {
  place-self: center;
  width: 90%;
}

.hpSearch {
  place-self: center;

  display: grid;
  width: 100%;

  grid-template-rows: auto;
  grid-template-columns: 4fr 1fr 2fr;
  grid-row-gap: 5px;
  grid-column-gap: 10px;
}

.containerOptionsAbsolute {
  text-align: center;
  position: absolute;
  width: 26.2%;

}

.optionsList {
  width: 100%;
  min-width: 223px;
  height: 100%;

  background-color: #f8f8f8;
  z-index: 2;
  border: 1px solid #999;
  border-radius: 5px;
  list-style: none;
  max-height: 100px;
  overflow-y: auto;

  margin: 0;
  padding-left: 0;

}

.optionsList li {
  padding: 0.1rem;
}

.optionsList li:hover {
  background-color: #cddae8;
  cursor: pointer;
  font-weight: 700;
}

.optionsList li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.hpSearchButton {
  place-self: center;
}

.hpExample {
  place-self: center end;
}

.noOptionsList {
  padding: 0.3rem;
  background-color: #f8f8f8;
  border: 1px solid #999;
  color: darkred;
  border-radius: 5px;
  min-width: 223px;
  font-weight: 500;
}

@media(max-width: 1000px) {

  .hpSearch {
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
  }

  .hpSearchInputBox {
    grid-row: 1;
    grid-column: 1/3;
  }

  .hpSearchButton {
    place-self: center;
  }

  .hpExample {
    place-self: center ;
  }
}
