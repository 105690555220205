.containerHelp{
  width: 100vw;
  min-height: 100vh;

  display: grid;

  grid-template-columns: auto;
  grid-template-rows: auto 300px;

  overflow-x: visible;
}

.contentHelp {
  place-self: center;
  width: 100%;
  height: 100%;

  display: grid;

  grid-template-columns: auto;
  grid-template-rows: 5rem auto auto;
  grid-row-gap: 30px;

  min-height: 100%;
}

.helpTitle {
  place-self: start center;
}

/* FAQ */

.faqBox {
  width: 65%;
  min-width: 900px;

  border: 2px solid gray;
  border-radius: 4px;

  place-self: start center;

  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

}

.faqTitle {
  width: 100%;

  font-weight: 700;
  font-size: 1.3rem;
  align-items: center;

  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;

}

.singleQuestion {
  width: 100%;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;

  border-top: 2px solid lightgray;

}

.question {
  place-self: center;

  height: 100%;
  width: 100%;
  box-sizing: border-box;

  padding-top: 13px;
  padding-bottom: 13px;

  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 1rem;
}

.question:hover {
  background-color: #d8e6fa;
  cursor: pointer;
}

.answersBox {
  border-top: 1px solid lightgray;
}

.singleAnswerBox {
  display: grid;
  grid-row-gap: 20px;
}

.answerText {
  text-align: justify;
  line-height: 1.4;
  padding: 20px;
}

.answerImage {
  place-self: center;
  padding: 20px;
}

/* Download Box */

.downloadBox {
  place-self: center;
  border: 2px solid gray;
  border-radius: 10px;

  width: 50%;

  margin-bottom: 20px;

  display: grid;
  grid-template-rows: auto auto auto auto auto auto;
  grid-template-columns: auto auto auto auto;
  grid-row-gap: 10px;
}

.downloadTitle {
  font-weight: 700;
  font-size: 1.3rem;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;

  grid-row: 1;
  grid-column: 1/5;
}

.annotationText {
  max-width: 90%;
  grid-row: 2;
  grid-column: 1/5;
  place-self: center;
  text-align: justify;
}

.specificDownload {
  padding-left: 20px;
  padding-right: 20px;

  grid-row: 3;
  grid-column: 1/5;

  place-self: center;
  text-align: justify;
}

.codingButton {
  grid-row: 4;
  grid-column: 1;

  place-self: center;
}

.lncRNAButton {
  grid-row: 4;
  grid-column: 2;

  place-self: center;
}

.miRNAButton {
  grid-row: 4;
  grid-column: 3;

  place-self: center;
}

.symbolsButton {
  grid-row: 4;
  grid-column: 4;

  place-self: center;
}

.allDownload {
  grid-row: 5;
  grid-column: 1/5;
  place-self: center;
  max-width: 90%;
  text-align: justify;
}

.allEntriesButton {
  grid-row: 6;
  grid-column: 1/5;
  place-self: center;
  margin-bottom: 20px;
}

@media(max-width: 1050px) {
  .downloadBox {
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-columns: auto auto;
  }


  .downloadTitle {
    grid-column: 1/3;
  }

  .annotationText {
    grid-column: 1/3;
  }

  .specificDownload {
    grid-column: 1/3;
  }

  .codingButton {
    grid-column: 1;
  }

  .lncRNAButton {
    grid-column: 2;
  }

  .miRNAButton {
    grid-row: 5;
    grid-column: 1;
  }

  .symbolsButton {
    grid-row: 5;
    grid-column: 2;
  }

  .allDownload {
    grid-row: 6;
    grid-column: 1/3;
  }

  .allEntriesButton {
    grid-row: 7;
    grid-column: 1/3;
  }


}
