.container {
  display: grid;
  grid-template-columns: 1fr auto auto auto 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.header {
  grid-row: 1;
}

.dropdown {
  grid-row: 2;
}

.layout {
  grid-column: 2;
}

.mirna {
  grid-column: 3;
}

.download {
  grid-column: 4;
  grid-row: 2;
}